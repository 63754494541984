@import url('https://fonts.googleapis.com/css2?family=Manjari:wght@100&display=swap');

:root{
  --bg0: #0A1828;
  --bg1: rgba(200,200,200,1);
  --bg2: #083845;

  --fg0: #FFFFFF;
  --fg1: #0A1828;
  --fg2: #BFA181;

  --8bg: rgba(10,24,40,0.8);
  --8bg2: rgba(8,56,69,0.8);
  --5bg: rgba(10,24,40,0.5);
  --2bg: rgba(10,24,40,0.2);

  --yellow: #D7B41B;
  --purple: #5B2078;
  --green: #23EC3D;
  --red: #ED2424;

  background-color: var(--bg0);
  color: var(--fg1);

  font-size: 20px;
  user-select: none;
}



body {
  margin: 0;
  font-family: 'Manjari', sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
}



.header {
  display: flex;
  flex-direction: column;
}

.space {
  background-color: var(--yellow);
}

.head-top {
  display: flex;
  padding: 30px 30px 10px 30px;
  justify-content: space-between;
  z-index: 0;
}

.title {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--fg2);
  text-shadow: 3px 3px 10px var(--bg0);
  z-index: 1;
}

.title > div > h1 {
  font-size: 55px;
  margin: 0;
}

.title > div > h2 {
  font-size: 20px;
  margin: 0;
}

.startButton {
  border-radius: 2px;
  border-style: solid;
  padding: 10px;
  background-color: var(--8bg);
}

.background-container {
  animation: moveImage 20s;
}
@keyframes moveImage {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 100%;
  }
  45% {
    opacity: 100%;
    transform: scale(1.15) translate(-20%, -20%);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: scale(1.15) translate(-20%, -20%);
  }
}

.social {
  align-self: center;
  display: flex;
  flex-direction: column;
}

.social > a {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  color: var(--fg2);
  text-decoration: none;
}

.social > a > img {
  width: 26px;
}

.nav-bar {
  position: sticky;
  top: 0;
  z-index: 2;

  background-color: var(--5bg);
  box-shadow: 0px 10px 20px -12px var(--fg1);
}

.nav-bar > ul {
  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  flex: 1;
  justify-content: space-around;
}

.nav-item {
  padding: 14px 20px 6px 20px;
  font-size: 24px;
  color: var(--fg2);
}

.nav-item:hover {
  background-color: rgba(60,60,60,0.4);
}



main {
  background-color: var(--bg1);
  padding: 10px;
  z-index: 1;
}

hr {
  margin: 40px;
  width: 70%;
  height: 1px;
}

.home-container {
  margin: 50px 10% 50px 10%;
}

.script-container {
  padding: 10px 50px 10px 50px;
  border-width: 10px;
  border-color: var(--bg2);
  border-style: ridge;
}

.script-author {
  padding: 5px;
  text-align: right;
}

.script-container > p {
  text-align: center;
  font-size: 24px;
}



.artist-container > h2 {
  line-height: 15px;
}

.artist-container > h3 {
  padding-top: 15px;
}

.artist-container > h4 {
  font-size: 12px;
  padding-bottom: 5px;
}

.artist-container > p {
  padding: 0px;
  margin: 5px;
}


.filter {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.filter-button {
  border-radius: 2px;
  border-style: solid;
  border-color: var(--fg2);
  color: var(--fg2);
  background-color: var(--bg0);
  padding: 15px 10px 10px 10px;
}

.filter-options {
  position: absolute;
  top: 100px;
  left: 0;
  background-color: var(--5bg);
  z-index: 15;
  border-radius: 4px;
}

.filter-options > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
}

.filter-options .filter-button {
  margin: 10px 25px 10px 25px;
}

.filter > label {
  padding-top: 5px;
}

#remove-filter {
  color: var(--red);
}

.all-container {
  display: flex;
  flex-direction: column;
}

.all-container > h1 {
  align-self: center;
}

.all-container > div {
  align-self: center;
  text-align: center;
}

.all-container > h2 {
  align-self: center;
}

.card-view {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card-container {
  position: relative;
  margin: 20px;
  box-shadow: 0px 0px 40px -2px var(--bg0);
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: var(--bg2);
  border-radius: 2px;
  border-width: 20px;
  border-style: inset;
}

.card-container:hover {
  box-shadow: 0px 0px 30px -10px var(--fg1);
}

.card-container > img {
  width: 250px;
}

.card-details {
  position: absolute;
  bottom: 0;

  height: 100%;
  width: 100%;

  background-image: linear-gradient(
    0, var(--bg0), rgba(0,0,0,0.9), rgba(0,0,0,0) 
  );

  z-index: 1;
  opacity: 0;
  color: var(--fg2);
  text-align: left;
}

.card-details:hover {
  opacity: 1;
}

.card-details > div {
  position: absolute;
  bottom: 0;
  padding: 15;

  display: flex;
  flex-direction: column;
  align-content: flex-end;
  flex-wrap: wrap;
}

.card-details > div > h3, .card-details > div > p {
  margin: 2;
}

.card-details > p {
  position: absolute;
  margin: 0;
  bottom: 0;
  right: 5px;
  font-size: 10px;
}

.paint-view {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 3;

  background-color: var(--2bg);

  display: flex;
  align-items: center;
  justify-content: center;
}


.details-container {
  background-color: var(--8bg);
  max-width: 70vw;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-color: var(--fg0);
  border-radius: 2px;
  border-width: 20px;
  border-style: inset;
}

.image-container {
  align-self: center;
}

.image-container > img {
  max-height: 60vh;
  max-width: 70vw;
}

.details-text {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 40px;
  width: 100%;
  height: 100%;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  color: var(--fg2);
}

.details-text > h1, .details-text > p {
  margin: 0 20px 0 20px;
}

.details-text > h1 {
  margin-top: 10px;
}

#tecyr {
  font-size: 14px;
}

#desc {
  margin-top: 20px;
}

#avail {
  position: absolute;
  bottom: 0px;
  right: 0px;

  font-size: 14px;
  text-align: right;
}

#notav {
  color: var(--red);
}

#av > span {
  color: var(--green);
}

.contact-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

form {
  display: flex;
  flex-direction: column;
  width: 510;
}

form > label {
  margin-top: 10px;
}

form > label > input, form > label > textarea {
  margin: 10px;
  border-radius: 5px;
  width: 150px;
  font-family: 'Manjari', sans-serif;
  font-size: 25px;

  padding: 8px;
  border: none;
  border-bottom: 1px solid #ccc;
  background-color: rgba(0,0,0,0);
  color: var(--fg1);

}

form > label > input {
  width: 150px; 
  transition: width 0.4s ease-in-out;
}

form > label > input:focus {
  display: block;
  width: 490px;
}

form > label > textarea {
  width: 490px;
  height: 200px;
  resize: none;
}

form > button {
  width: 100px;
  height: 40px;
  margin: 10px;
  padding-top: 7px;

  align-self: flex-end;
  
  border-radius: 20px;
  border: none;
  background-color: var(--fg1);
  
  color: var(--bg1);
  font-family: 'Manjari', sans-serif;
  font-size: 20px;
}

.submitted-message {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 4;

  display: flex;
  align-items: center;
  justify-content: center;
}

.submitted-message > div {
  padding: 10px;
  background-color: var(--8bg);
  box-shadow: 0px 0px 20px -5px var(--fg0);
  border-radius: 3px;
}


footer {
  position: sticky;
  z-index: 4;
  bottom: 0px;
  align-self: flex-end;
  padding: 10px;
  padding-right: 20px;
  font-size: 15px;
  background-color: var(--bg0);
  color: var(--fg2);
}
